
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-style {
  &__icon {
    width: 24px;
    display: block;
  }
  &__image {
    max-width: 260px;
  }
}
