
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.sidebar-links {
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: grab;
  }

  &__example {
    margin-bottom: $base-gutter;
  }

  &__settings {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $base-gutter;
    position: relative;
  }
}
