
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.portal-style {
  .esmp-tabs-tab-active {
    .esmp-svg-icon {
      fill: $color-white;
    }
  }
  .block__title {
    color: $color-black-op-70;
  }
  .esmp-upload {
    width: 100%;

    .esmp-upload__label {
      display: inline-block;
      width: 100%;
      padding: 12px;
    }
  }
}
